.button {
  max-width: 140px;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  background-color: $dark;
  color: $light;
  padding: 10px;
  border-radius: 5px;
  font-weight: 300;
  @include typo-size($second_font, map-get($font-sizes, xxs));
  &-check {
    width: 18px;
    fill: white;
    animation-name: rotate();
    @include animate(rotate, 0.8s, linear, infinite);
  }
  @media (min-width: map-get($breakpoints, tablet)) {
    @include typo-size($second_font, map-get($font-sizes, xs));
  }
}
.button:hover {
  animation-name: btnHover();
  outline: none;
  @include animate(btnHover, 0.8s, infinite, alternate-reverse);
}

.button:disabled {
  background-color: $red;
  cursor: default;
}
