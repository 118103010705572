.skillsSection {
  padding: 20px 8%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &-resume {
    padding: 40px;
  }
  &-skills {
    padding: 25px 0;
    width: 90%;
    display: flex;
    flex-direction: column;
    &-header {
      display: flex;
      justify-content: center;
      padding: 14px 0;
      svg {
        width: 25px;
        margin: 0 14px;
      }
      .svg-down {
        transform: rotate(180deg);
      }
      &-title {
        text-align: center;
        @include typo-size($main_font, map-get($font-sizes, sl));
        @include gradient();
      }
    }
  }
}

@media (min-width: map-get($breakpoints, tablet)) {
  .skillsSection {
    &-skills {
      &-title {
        @include typo-size($main_font, map-get($font-sizes, l));
      }
    }
  }
}
@media (min-width: map-get($breakpoints, desktop)) {
  .skillsSection {
        max-width: 1700px;
    margin: 0 auto;
    &-resume {
      width: 100%;
      max-width: 200px;
    }
  }
}
