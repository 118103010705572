$light: #ffffff;
$dark: #0a0a0a;
$red: #e85f5c;
$light-gray: #f6f4f4;
$gray: #e7e7e7;
$dark-gray: #c4c2c2;

$main_font: 'Cinzel', serif;
$second_font: 'Josefin Sans', sans-serif;
$third_font: 'Oswald', sans-serif;

$breakpoints: (
  tablet: 769px,
  desktop: 1023px,
);

$font-sizes: (
  xxxl: 7em,
  xxl: 4.5em,
  xl: 3.4em,
  sxl: 3.2em,
  l: 2em,
  sl: 1.8em,
  s: 1.5em,
  ss: 1.4em,
  xs: 1.1em,
  xxs: 0.9em,
);
