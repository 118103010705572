.skillGroup-container {
  width: 100%;
  margin: 0 auto;
  @include showUp();
  .skills-logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    svg {
      width: 40px;
      height: 40px;
      margin: 20px;
      fill: black;
    }
  }
  .skillsGroup {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border: dashed $dark 1px;
    border-radius: 3px;
    margin: 10px auto;
    max-width: 240px;
    width: 100%;

    h3 {
      margin: 0 auto;
      font-weight: 400;
      padding: 5px 0;
      @include gradient();
      @include typo-size($second_font, map-get($font-sizes, xs));
    }

    &-content {
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      span {
        padding: 5px;
        color: rgb(62, 62, 62);
        @include typo-size($main_font, map-get($font-sizes, xxs));
      }
    }
  }
  .skillsGroup:hover {
    @include hoverBox();
  }
}

@media (min-width: map-get($breakpoints, tablet)) {
  .skillGroup-container {
    justify-content: space-between;
    .skills-logos {
      svg {
        width: 50px;
        height: 50px;
      }
    }
    .skillsGroup {
      display: flex;
      flex-direction: column;
      padding: 15px 0;
      h3 {
        @include gradient();
        @include typo-size($second_font, map-get($font-sizes, s));
      }

      &-content {
        span {
          @include typo-size($main_font, map-get($font-sizes, xs));
        }
      }
    }
  }
}

@media (min-width: map-get($breakpoints, desktop)) {
  .skillGroup-container {
    .skillsGroup {
      &-content {
        span {
        }
      }
    }
  }
}
