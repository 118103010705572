#language-toggle {
  position: absolute;
  right: 36px;
  top: 36px;
  border-radius: 20px;
  border: none;
  font-size: 1em;
  cursor: pointer;
  transition: 300ms linear;
  @media (min-width: map-get($breakpoints, tablet)) {
    top: 45px;
    right: 6%;
    font-size: 1.2em;
  }
  @media (min-width: map-get($breakpoints, desktop)) {
    font-size: 1.5em;
    top: 45px;
    right: 6%;
  }
}
#language-toggle:hover {
  transform: scale(1.2);
}
.nav {
  display: none;

  @media (min-width: map-get($breakpoints, tablet)) {
    display: block;
    display: flex;
    padding: 40px 8%;

    &-logo {
      width: 100%;
      max-width: 110px;
    }
    &-links {
      list-style: none;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      @include typo-size($main_font, map-get($font-sizes, s));
      &-link {
        text-decoration: none;
        color: $dark;
        margin: 0 -0.25rem;
        padding: 0 0.25rem;
        box-shadow: inset 0 0 0 0 $dark;
        transition: color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      }
    }
    &-links:hover {
      cursor: pointer;
    }
    &-links-link:hover {
      box-shadow: inset 220px 0 0 0 $dark;
      color: $light;
    }
  }

  @media (min-width: map-get($breakpoints, desktop)) {
    padding: 40px 100px;
    max-width: 1400px;
    margin: 0 auto;
    &-logo {
      max-width: 120px;
    }
    &-links {
      @include typo-size($main_font, map-get($font-sizes, sl));
    }
  }
}

.burger-nav {
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $dark;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: lighten($color: $dark, $amount: 40);
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: $light;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 2em 0;
    font-weight: 300;
    @include typo-size($main_font, map-get($font-sizes, xs));
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: $dark;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 1em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
    color: $light;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }

  .bm-item:hover {
    color: #ffffff;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  @media (min-width: map-get($breakpoints, tablet)) {
    .bm-burger-button,
    .bm-burger-bars,
    .bm-cross-button,
    .bm-item,
    .bm-item-list,
    .bm-menu-wrap {
      display: none;
    }
  }
}

.progress-bar {
  position: fixed;
  content: '';
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  @include scrollgradient();
  transform-origin: 0%;
}
