.profile {
  padding: 100px 8% 20px 8%;
  &-text {
    max-width: 500px;
    &-name {
      font-weight: 100;
      width: 300px;
      @include typo-size($main_font, map-get($font-sizes, sxl));
      @include gradient();
    }
    &-intro {
      width: 100%;
      padding-top: 30px;
      line-height: 1.1;
      font-weight: 300;
      text-align: start;
      @include typo-size($second_font, map-get($font-sizes, xs));
      &-paragraphs {
        padding-top: 15px;
      }
    }
  }
  &-imgContainer {
    display: none;
  }
  @media (min-width: map-get($breakpoints, tablet)) {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    &-text {
      width: 60%;
      &-name {
        @include typo-size($main_font, map-get($font-sizes, xl));
      }
      &-intro {
        @include typo-size($second_font, map-get($font-sizes, xs));
      }
    }
    &-imgContainer {
      display: block;
      display: flex;
      width: 40%;
      align-items: center;
      &-picture {
        height: 290px;
        width: 100%;
        max-width: 200px;
        object-fit: cover;
        border-radius: 4px;
        margin: 0 auto;
      }
    }
  }
  @media (min-width: map-get($breakpoints, desktop)) {
    padding: 40px 100px;
    max-width: 1400px;
    margin: 0 auto;
    &-text {
      width: 70%;
      max-width: none;
      padding-right: 10px;
      &-name {
        @include typo-size($main_font, map-get($font-sizes, xxl));
      }
      &-intro {
        @include typo-size($second_font, map-get($font-sizes, ss));
      }
    }
    &-imgContainer {
      width: 30%;
      &-picture {
        height: 430px;
        max-width: 310px;
      }
    }
  }
}
