.projectsSection {
  position: relative;
  padding: 20px 8% 20px 8%;
  margin: 20px 0;
  background-color: $light;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-header {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    svg {
      width: 25px;
      margin: 0 14px;
    }
    .svg-down {
      transform: rotate(180deg);
    }
    &-title {
      text-align: center;
      padding-top: 20px;
      @include typo-size($main_font, map-get($font-sizes, sl));
      @include gradient();
    }
  }
  &-selectTypes {
    padding: 7px;
    margin: 10px 0 10px 150px;
    @include typo-size($main_font, map-get($font-sizes, xxs));
  }
  &-container {
    &-project {
      color: $dark;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      border: 1px solid $dark;
      border-radius: 1px;
      padding: 10px;
      margin: 35px 0;
      width: 400px;
      &-name {
        padding: 12px 0;
        text-align: center;
        font-weight: 600;
        @include typo-size($main_font, map-get($font-sizes, xs));
      }
      &-content {
        &-links {
          display: flex;
          flex-direction: column;
          align-items: center;
          &-img {
            width: 100%;
            height: 100%;
            max-width: 400px;
            object-fit: cover;
            border-radius: 5px;
          }
          &-linksContainer {
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding: 20px 0 10px 0;
            &-website {
              background-color: $dark;
              color: $light;
              padding: 7px 12px;
              border-radius: 15px;
              text-decoration: none;
              cursor: pointer;
              margin: 0 2px;
              @include button();
            }
            &-website:hover {
              background-color: $red;
              @include gradientHover();
            }
          }
        }

        &-description {
          text-align: justify;
          width: 100%;
          padding: 10px;
          font-weight: 300;
          @include typo-size($second_font, map-get($font-sizes, xxs));
        }
        &-tags {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-around;
          span {
            text-align: center;
            width: 100%;
            background-color: $gray;
            color: $dark;
            border-radius: 1px;
            padding: 0.2em;
            margin: 0.2em;
            @include typo-size($main_font, map-get($font-sizes, xxs));
          }
        }
      }
    }
    &-project:hover {
      @include hoverBox();
    }
  }
}

@media (min-width: map-get($breakpoints, tablet)) {
  .projectsSection {
    &-header {
      &-title {
        @include typo-size($main_font, map-get($font-sizes, l));
        padding-top: 25px;
      }
    }
    &-selectTypes {
      margin: 10px 0 10px 471px;
    }
    &-container {
      &-project {
        width: 600px;
        &-name {
          padding: 5px 0;
          font-weight: 400;
          @include typo-size($main_font, map-get($font-sizes, s));
        }
        &-content {
          &-description {
            @include typo-size($second_font, map-get($font-sizes, xs));
          }
          &-tags {
            span {
              padding: 0.3em;
              margin: 0.4em;
              @include typo-size($main_font, map-get($font-sizes, xxs));
            }
          }
        }
      }
    }
  }
}
@media (min-width: map-get($breakpoints, desktop)) {
  .projectsSection {
    padding: 10px 100px;
    max-width: 1400px;
    margin: 0 auto;
    &-selectTypes {
      margin: 10px 0 10px 800px;
      @include typo-size($main_font, map-get($font-sizes, xs));
    }
    &-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      &-project {
        max-width: 350px;
        margin: 20px;
        &-name {
          @include typo-size($main_font, map-get($font-sizes, sl));
        }
        &-content {
          &-links {
            &-img {
              max-width: 280px;
              height: 200px;
            }
          }
          &-description {
            height: 165px;
            @include typo-size($second_font, map-get($font-sizes, ss));
          }
          &-tags {
            span {
              max-width: 160px;
              @include typo-size($main_font, map-get($font-sizes, xs));
            }
          }
        }
      }
    }
  }
}
