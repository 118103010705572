.formSection {
  padding: 10px 8% 20px 8%;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &-header {
    font-weight: 500;
    @include typo-size($main_font, map-get($font-sizes, sl));
    @include gradient();
  }
  &-form {
    padding: 20px 0;
    text-align: center;
    &-inputWrapper {
      label {
        display: none;
      }
      input,
      textarea {
        max-width: 500px;
        border: 1px solid black;
        padding: 5px;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 1px;
      }
      input::placeholder,
      textarea::placeholder {
        @include typo-size($second_font, map-get($font-sizes, xxs));
        font-weight: 300;
      }
      textarea {
        height: 200px;
      }
    }
  }
}
@media (min-width: map-get($breakpoints, tablet)) {
  .formSection {
    text-align: center;
    &-form {
      input,
      textarea {
        padding: 12px;
        margin-bottom: 13px;
      }
      input::placeholder,
      textarea::placeholder {
        @include typo-size($second_font, map-get($font-sizes, s));
      }
      &-btn {
        padding: 10px;
        @include typo-size($second_font, map-get($font-sizes, xs));
      }
    }
  }
}

@media (min-width: map-get($breakpoints, desktop)) {
  .formSection {
    padding: 40px 100px;
    text-align: center;
    &-header {
      font-weight: 600;
      @include typo-size($main_font, map-get($font-sizes, l));
    }
    &-form {
      input,
      textarea {
        padding: 15px;
        margin-bottom: 15px;
      }
      input::placeholder,
      textarea::placeholder {
        @include typo-size($second_font, map-get($font-sizes, s));
      }
      button {
        margin-top: 20px;
      }
    }
  }
}
