.socialMediaContainer {

  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0 30%;
  a > svg {
    width: 25px;
    margin: 0 10px;
  }
  a > svg:hover {
    fill: $red;
    animation-name: rotate();
    @include animate(rotate, 1s, ease, 1);
  }
}

@media (min-width: map-get($breakpoints, tablet)) {
  .socialMediaContainer {
    padding: 0 40%;
    a > svg {
      width: 30px;
    }
  }
}

@media (min-width: map-get($breakpoints, desktop)) {
  .socialMediaContainer {
    padding: 0 40%;
    a > svg {
      width: 35px;
    }
  }
}
