@mixin box-shadow($x, $y, $blurr) {
  box-shadow: $x $y $blurr darken($bgd-gray, 20%);
}

@mixin typo-size($typo, $size) {
  font-family: $typo;
  font-size: $size;
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin gradient() {
  background-color: $light;
  background-image: -webkit-linear-gradient(
    350deg,
    rgba(232, 95, 92, 1) 0%,
    rgba(10, 10, 10, 1) 74%
  );
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

@mixin scrollgradient() {
  background: -webkit-linear-gradient(
    162deg,
    rgba(232, 95, 92, 1) 26%,
    rgba(10, 10, 10, 1) 100%
  );
}

@for $i from 1 through 10 {
  .skillsbox-#{$i} {
    animation-delay: 0.2 * $i;
  }
}

@keyframes showUpAnimation {
  0% {
    opacity: 0;
    transform-origin: top;
  }
  100% {
    opacity: 1;
    transform-origin: bottom;
  }
}

@mixin showUp() {
  opacity: 0;
  animation-name: showUpAnimation;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@mixin hoverBox() {
  box-shadow: 3px 3px 3px $dark-gray;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100%;
  }
}
@mixin button() {
  background: linear-gradient(to right, $dark 0%, $red 50%, $dark 100%);
  background-size: 500%;
  border: none;
  outline: none;
}
@mixin gradientHover() {
  animation-name: gradient;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes btnHover {
  0% {
    transform: scale(1);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  }

  100% {
    transform: scale(1.02);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  }
}

//background animation
.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, $light 55%, $light-gray 50%);
  bottom: 0;
  left: -70%;
  opacity: 0.5;
  position: fixed;
  right: -90%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 15s;
}

.bg3 {
  animation-duration: 9s;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
