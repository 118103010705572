footer {
  height: 70px;
  width: 100%;

  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  @include typo-size($second_font, map-get($font-sizes, xxs));
  .icon {
    display: none;
    margin: 0 10px;
    width: 20px;
    cursor: pointer;
  }
  .icon:hover {
    fill: $red;
  }
  @media (min-width: map-get($breakpoints, desktop)) {
    height: 110px;
    @include typo-size($second_font, map-get($font-sizes, xs));
  }
}
